<template>
  <div v-if="$store.state.settings.init" class="users">
    <div v-if="selectedRole" class="admin-popup">
      <span class="material-icons close-icon" @click="exitChangeRole"
        >close</span
      >
      <h3>
        Change user role for:<br /><span>{{ selectedUser.email }}</span>
      </h3>
      <v-select
        class="user-role-chooser"
        :clearable="false"
        :searchable="false"
        v-model="selectedRole"
        label="name"
        :options="Object.keys(userRoles)"
      ></v-select>
      <button @click="saveUserRole">Save</button>
    </div>

    <div v-if="selectedCompany" class="admin-popup">
      <span class="material-icons close-icon" @click="exitChangeCompany"
        >close</span
      >
      <h3>
        Change company on: <br /><span>{{ selectedUser.email }}</span>
      </h3>
      <v-select
        class="user-role-chooser"
        :clearable="false"
        :searchable="false"
        v-model="selectedCompany"
        :options="getCompanyListSelectOptions"
      ></v-select>
      <button @click="saveUserCompany">Save</button>
    </div>

    <h1>Users</h1>

    <div
      v-if="Object.keys(requestedRemovalUsers).length > 0"
      class="users-requested-removal"
    >
      <h2>Following users have requested their account to be deleted:</h2>
      <div
        v-for="userId in Object.keys(requestedRemovalUsers)"
        :key="'remove-' + userId"
      >
        {{ getUserById(userId).vismaId }} - {{ getUserById(userId).email }} -
        {{ getUserById(userId).name }} - {{ getCompanyNameByUserId(userId) }}
      </div>
    </div>

    <div class="controls">
      <div class="search">
        <input type="text" placeholder="Search" v-model="search" />
      </div>
      <div class="meta">
        <span
          >Logged in once: {{ getSignedInUsers.length }} /{{
            getUserBySearch("").length
          }}</span
        >
        <span
          >Showing: {{ getUserBySearch(search).length }} /
          {{ getUserBySearch("").length }}
        </span>
      </div>
    </div>

    <div class="admin-table user-table">
      <div class="table-header">
        <span class="email label">E-mail</span>
        <span class="role label">User role</span>
        <span class="first-sign-in label">First sign in</span>
        <span class="last-open label">Last open</span>
        <span class="last-open label">Last open (web)</span>
        <span class="app-version label">App version</span>
        <span class="company label">Company</span>
      </div>
      <div
        v-for="user in getUserBySearch(search)"
        :key="'user-' + user.uid"
        @click="navigateToUser(user.uid)"
      >
        <span class="email">
          <div class="top">
            <span
              v-if="
                user.registerAccepted !== undefined &&
                user.registerAccepted === false
              "
              class="sidebar-notification"
              >!</span
            >
            {{ user.email }}
            <img :src="require('../assets/flags/' + user.lang + '.png')" />
          </div>
          <div class="bottom">
            <div class="notification">
              <span
                class="material-icons"
                :class="{
                  off: getPushNotificationsSettings(user.uid).chat == false,
                }"
                >{{
                  getPushNotificationsSettings(user.uid).chat
                    ? "notifications"
                    : "notifications_off"
                }}</span
              >
              Chat
            </div>
            <div class="notification">
              <span
                class="material-icons"
                :class="{
                  off:
                    getPushNotificationsSettings(user.uid).deliveryInfo ==
                    false,
                }"
                >{{
                  getPushNotificationsSettings(user.uid).deliveryInfo
                    ? "notifications"
                    : "notifications_off"
                }}</span
              >
              Delivery
            </div>
            <div class="notification">
              <span
                class="material-icons"
                :class="{
                  off:
                    getPushNotificationsSettings(user.uid).newsAndOffers ==
                    false,
                }"
                >{{
                  getPushNotificationsSettings(user.uid).newsAndOffers
                    ? "notifications"
                    : "notifications_off"
                }}</span
              >
              News & Offers
            </div>
          </div>
        </span>
        <span class="role">
          {{ userRoles[user.role].name }}
          <!-- <span class="material-icons edit-icon" @click.stop="initChangeRole(user)" v-if="userRole.slug === 'superAdmin'">edit</span> -->
        </span>
        <span class="first-sign-in">
          <span v-if="user.firstSignIn !== undefined">{{
            user.firstSignIn.substring(0, 16)
          }}</span>
        </span>
        <span class="last-open">
          <span
            v-if="
              analyticsDocById(user.id) !== undefined &&
              analyticsDocById(user.id).lastOpen !== undefined
            "
            >{{
              analyticsDocById(user.id).lastOpen.toDate()
                | moment("YY-MM-DD - HH:ss")
            }}</span
          >
        </span>
        <span class="last-open">
          <span
            v-if="
              analyticsDocById(user.id) !== undefined &&
              analyticsDocById(user.id).lastOpenWeb !== undefined
            "
            >{{
              analyticsDocById(user.id).lastOpenWeb.toDate()
                | moment("YY-MM-DD - HH:ss")
            }}</span
          >
        </span>
        <span class="app-version">
          <span
            v-if="
              analyticsDocById(user.id) !== undefined &&
              analyticsDocById(user.id).appVersion !== undefined
            "
            >{{ analyticsDocById(user.id).appVersion }}</span
          >
        </span>
        <span
          class="company"
          v-if="user.companies !== undefined && user.companies.length < 2"
        >
          {{ getCompanyNameByVismaId(user.companyId) }}
          <span
            class="material-icons edit-icon"
            @click.stop="initChangeCompany(user)"
            v-if="
              userRole.slug === 'superAdmin' &&
              getCompanyNameByVismaId(user.companyId) &&
              user.role === 'superAdmin'
            "
            >edit</span
          >
        </span>

        <span class="company" v-else>
          <span v-for="companyId in user.companies" :key="user.id + companyId">
            {{ companyId }}
            {{ getCompanyNameByVismaId(companyId) }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "Users",
  data() {
    return {
      name: "Users",
      createEmail: "",
      loading: false,
      message: "",
      search: "",
      selectedRole: "",
      selectedUser: null,
      selectedCompany: "",
    };
  },
  mounted() {},
  created() {},
  computed: {
    ...mapState({
      userList: (state) => state.users.userList,
      appSettings: (state) => state.settings.appSettings,
      userAnalytics: (state) => state.analytics.analyticsDocs,
    }),
    ...mapGetters("users", [
      "getUserBySearch",
      "getSignedInUsers",
      "requestedRemovalUsers",
      "getUserById",
      "getCompanyNameByUserId",
      "getPushNotificationsSettings",
    ]),
    ...mapGetters("settings", [
      "userRoles",
      "userRole",
      "getCompanyNameByVismaId",
      "getCompanyListSelectOptions",
    ]),
    ...mapGetters("analytics", ["analyticsDocById"]),
  },
  methods: {
    initChangeCompany: function (user) {
      this.selectedCompany = this.getCompanyNameByVismaId(user.companyId);
      this.selectedUser = user;
    },
    navigateToUser: function (id) {
      this.$router.push({ name: "SingleUser", params: { id } });
    },
    initChangeRole: function (user) {
      this.selectedRole = user.role;
      this.selectedUser = user;
    },
    exitChangeRole: function () {
      this.selectedUser = null;
      this.selectedRole = null;
    },
    exitChangeCompany: function () {
      this.selecteduser = null;
      this.selectedCompany = null;
    },
    saveUserCompany: function () {
      const oldCompany = this.selectedUser.companyId;
      const newCompany = this.selectedCompany.id;

      if (oldCompany === newCompany) {
        this.exitChangeCompany();
      } else {
        this.$store
          .dispatch("users/updateUserCompany", {
            uid: this.selectedUser.uid,
            company: parseInt(newCompany),
          })
          .then(() => {
            this.exitChangeCompany();
          });
      }
    },
    saveUserRole: function () {
      if (this.selectedRole === this.selectedUser.role) {
        this.exitChangeRole();
      } else {
        this.$store
          .dispatch("users/updateUserRole", {
            uid: this.selectedUser.uid,
            role: this.selectedRole,
          })
          .then(() => {
            this.exitChangeRole();
          });
      }
    },
    consoleUid: function (uid) {
      console.log("UID: " + uid);
    },
  },
};
</script>
